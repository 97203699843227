function callMe() {
    const salesinputBtn = document.querySelector('.sales__input-btn');
    const salesWin = document.querySelector('.sales__ok');
    const botInfo = {
        TOKEN: '5212895158:AAGbfQnhQIgsOZlaYiLyc1uLnqOASYk-rdQ',
        chatID: '-1001592991864'
    };

    // Перезвонить мне
    salesinputBtn.addEventListener('click', () => {
        const salesinputTel = document.getElementById('sales__input-tel');

        if (salesinputTel.value) {
            const msgCall = `
			Передзвоніть мені: ${salesinputTel.value}
		`;

            fetch(`https://api.telegram.org/bot${botInfo.TOKEN}/sendMessage?chat_id=${botInfo.chatID}&text=${msgCall}`, {
                    method: "GET"
                })
                .then(succes => {
                        const salesOk = document.createElement('span').textContent = `Заявка успішно надіслана`;
                        salesWin.append(salesOk);
                }, error => {
                    const salesOk = document.createElement('span').textContent = `Щось пішло не так!`;
                        salesWin.append(salesOk);
                });

            salesinputTel.clear;
        } else {
            alert('Введіть номер телефону');
        }

    });
}

module.exports = callMe;
//export default callMe;