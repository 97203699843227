function dbToTelegram() {
    const popupWindowInfo = document.querySelector('.popup__window-info h3');
    const sendToTg = document.getElementById('popup__window-sendtg');
    const priceTg = document.querySelector('.price');
    const dropdownTg = document.querySelector('.dropdown-select');
    const dateTg = document.getElementById('start');
    const telTg = document.getElementById('popup__window-tel');
    const popupBg = document.querySelector('.popup__bg');

    const botInfo = {
        TOKEN: '5212895158:AAGbfQnhQIgsOZlaYiLyc1uLnqOASYk-rdQ',
        chatID: '-1001592991864'
    };

    sendToTg.addEventListener('click', () => {
        const sendObj = {
            title: popupWindowInfo.textContent,
            price: priceTg.textContent,
            people: dropdownTg.selectedIndex + 1,
            date: dateTg.value,
            phone: telTg.value
        }

        if (telTg.value) {
            let www = sendObj.textContent = `
		Номер: ${sendObj.title}%0A
		Ціна: ${sendObj.price}%0A
		Кількість чоловік: ${sendObj.people}%0A
		Дата: ${sendObj.date}%0A
		Телефон: ${sendObj.phone}%0A
	    `;

            fetch(`https://api.telegram.org/bot${botInfo.TOKEN}/sendMessage?chat_id=${botInfo.chatID}&text=${www}`, {
                    method: "GET"
                })
                .then(succes => {
                    alert(`Заявка успішно надіслана, найближчим часом з Вами зв'яжеться наш менеджер для уточнення деталей`);
                    setTimeout(() => {
                        popupBg.style.display = 'none';
                    }, 1000);
                    //popupBg.style.display = 'none';
                }, error => {
                    alert('Щось пішло не так!');
                    setTimeout(() => {
                        popupBg.style.display = 'none';
                    }, 1000);
                });
        } else {
            alert('Введіть номер телефону');
        }
    });
};

module.exports = dbToTelegram;
//export default dbToTelegram;