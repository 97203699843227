/* import dbToTelegram from './modules/telegram.js';
import callMe from './modules/callback.js';
import sliderRooms from './modules/slider.js'; */

window.addEventListener('DOMContentLoaded', () => {
    const dbToTelegram = require('./modules/telegram');
    const callMe = require('./modules/callback');
    const sliderRooms = require('./modules/slider');

    const databaseRooms = async() => {
        await fetch('./rooms.json')
            .then(data => data.json())
            .then(res => localStorage.setItem('rooms', JSON.stringify(res.rooms)))
            .catch(() => {
                alert('Щось пішло не так, будь ласка, перезавантажте сторінку!');
            })
            .finally(() => {
                const parse = JSON.parse(localStorage.getItem('rooms'));
                //const parseNew = parse.slice(0);
                parse.forEach(e => {
                    const crDiv = document.querySelector('.hotel__rooms');

                    crDiv.innerHTML += `
                        <div class="hotel__cart" data-card="${e.id}" itemscope itemtype="https://schema.org/Hotel"><img src="${e.img[0]}" class="card__img" alt="${e.title}" title="${e.title}" itemprop="photo">
                            <div class="hotel__price">
                                <span class="hotel__price-bg"></span>
                                <div class="hotel__price-title" itemprop="name"><h2>${e.title}</h2></div>
                                <div class="hotel__price-buy" itemprop="priceSpecification" itemscope itemtype="https://schema.org/UnitPriceSpecification">
                                    <div class="hotel__price-prices" itemprop="price">від ${e.price[0]} грн</div>
                                    <button class="button__buy" data-btn-buy>Бронь</button>
                                </div>
                            </div>
                        </div>
                `;

                });
            })

    };

    databaseRooms();

    callMe();

    const navScrool = document.querySelector('.nav__scrool');

    function navScrollMenu() {
        const offset = 100;
        window.addEventListener('scroll', function() {
            if (window.pageYOffset >= offset) {
                navScrool.classList.add('nav__show');
                navScrool.classList.remove('nav__hide');
            } else if (window.pageYOffset <= offset) {
                navScrool.classList.remove('nav__show');
                navScrool.classList.add('nav__hide');
            }
        });
    };
    navScrollMenu();

    // Отлавливаем кнопку брони по клику
    window.addEventListener('click', (event) => {

        if (event.target.hasAttribute('data-btn-buy')) {
            const card = event.target.closest('.hotel__cart');

            function getInfo(prod) {
                JSON.parse(localStorage.getItem('rooms')).forEach((e) => {
                    if (e.id == prod) {
                        const popupBg = document.querySelector('.popup__bg');
                        navScrool.classList.remove('show');
                        popupBg.style.display = 'flex';

                        popupBg.innerHTML = `
                        <div class="popup__window">
                            <div class="container">
                                <div class="popup__window-wrapper">
                                    <div class="popup__window-wrapper-img">
                                    <div class="offer__slider">
                                        <div class="offer__slider-wrapper">
                                            <div class="offer_slider-inner">
    
                                            </div>
                                        </div>
                                        <div class="offer__slider-counter">
                                            <div class="offer__slider-prev">
                                                <img src="src/icons/left.svg" alt="prev" title="previous">
                                            </div>
                                            <span id="current">02</span>
                                            /
                                            <span id="total">04</span>
                                            <div class="offer__slider-next">
                                                <img src="src/icons/right.svg" alt="next" title="next">
                                            </div>
                                        </div>
                                    </div>
                                        
                                    </div>
                                    <div class="popup__window-info">
                                        <h3>${e.title}</h3>
                                        <p class="price">${e.price[0]} грн</p>
                                            <div class="popup__window-select-options">
                                                <div class="popup__window-select-dropdown">
                                                    <p>Кількість осіб</p>
                                                    <div class="dropdown">
                                                        <select name="one" class="dropdown-select"></select>
                                                </div>
                                                </div>
                                                <div class="popup__window-select-input">
                                                    <p>Дата</p>
                                                    <input type="date" id="start" name="trip-start" value="" min="2018-01-01" max="2030-12-31" minlength="6" maxlength="15">
                                                </div>
                                            </div>
                                            <div class="popup__window-check">
                                                <input type="tel" id="popup__window-tel" placeholder="+38 (050) 123-45-67">
                                                <button class="popup__window-sendtg" id="popup__window-sendtg">Забронювати</button>
                                            </div>
                                        <p class="popup__window-description">${e.description}</p>
                                        <button id="card-close" class="popup_btn-close">X</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                `;

                        const sliderItem = document.querySelector('.offer_slider-inner');

                        e.img.forEach(event => {
                            sliderItem.innerHTML += `
                            <div class="offer__slide">
                                <img src="${event}" class="popup__window-img" alt="Rooms" title="Rooms">
                            </div>
                            `;

                        });

                        const selectValue = document.querySelector('.dropdown-select');

                        e.people.forEach(event => {
                            selectValue.innerHTML += `
                                <option value="${event}">${event}</option>
                            `;
                        });

                        selectValue.addEventListener('change', () => {
                            const priceList = document.querySelector('.price');
                            if (selectValue.selectedIndex == 1) {
                                priceList.textContent = e.price[1] + ` грн`;
                            } else {
                                priceList.textContent = e.price[0] + ` грн`;
                            }
                        });

                        sliderRooms();
                    }
                });
            }


            getInfo(card.dataset.card);

            dbToTelegram();

            const popupBtnClose = document.querySelectorAll('.popup_btn-close, .popup_btn-close-mobile'),
                popupBg = document.querySelector('.popup__bg');

            popupBtnClose.forEach(e => {
                e.addEventListener('click', () => {
                    popupBg.style.display = 'none';
                    navScrool.classList.add('show');
                    navScrool.classList.remove('hide');
                });
            });
        }

    });

    function mobileMenu() {
        const menuBtn = document.querySelectorAll('.nav__menu-mobile-btn');
        const navMenuBg = document.querySelector('.nav__menu-mobile-bg');
        const navMenuBtnClose = document.querySelector('.nav__menu-btn-close');

        menuBtn.forEach(el => {
            el.addEventListener('click', () => {
                navMenuBg.style.cssText = `
                    opacity: 1;
                    transform: translate(0px, 0px);
                    transition: all 0.8s ease 0s;
                `;
            });
        });

        navMenuBtnClose.addEventListener('click', () => {
            navMenuBg.style.cssText = `
                    opacity: 0;
                    transform: translate(-100%, 0px);
                    transition: all 0.8s ease 0s;
                `;
        })
    }
    mobileMenu();

    function footerDate() {
        const date = document.querySelector('.footer__copyright-protect-date');

        date.innerHTML = new Date().getFullYear();
    }

    footerDate();
});